.contact-container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: hsla(0, 0%, 89%, .4);
    border-radius: 44px;
    bottom: 132px;
    padding: 16px 2px;
    position: fixed;
    right: 14px;
    z-index: 12
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center
}

.tooltip-container {
    box-sizing: border-box;
    color: #37383a;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    height: 60px;
    line-height: 16px;
    margin-bottom: 38px;
    padding: 10px 6px 0;
    position: relative;
    width: 60px
}

.tooltip-container:hover .tooltip {
    visibility: visible
}

.tooltip-container :last-child {
    margin-bottom: 0
}

.tooltip-container .tooltip {
    align-items: center;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px #0000001a;
    color: #000;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    justify-content: center;
    left: -16px;
    line-height: 16px;
    padding: 2px;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    transform-style: preserve-3d;
    transition: visibility .02s ease-in;
    visibility: hidden;
    width: 220px;
    z-index: 200
}

.tooltip-container .tooltip img {
    display: inline-block;
    height: 250px;
    width: 220px;
}

.tooltip-container .tooltip:before {
    background: #fff;
    box-shadow: 0 0 16px #00000014;
    transform: rotate(45deg) translate(-50%) translateZ(-1px);
    transform-origin: 50% 50%;
    z-index: -1
}

.tooltip-container .tooltip:after,
.tooltip-container .tooltip:before {
    border-radius: 3px;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: -8px;
    top: 50%;
    width: 10px
}

.tooltip-container .tooltip:after {
    background-color: #fff;
    transform: rotate(45deg) translate(-50%);
    transform-origin: 50% 50%;
    z-index: 400
}

.tooltip-container .content,
.tooltip-container .tooltip .text {
    align-items: center;
    display: flex
}

.tooltip-container .content {
    flex-direction: column;
    justify-content: center
}

.tooltip-container .mt2 {
    margin-top: 2px
}

.tooltip-container .mt4 {
    margin-top: 4px
}

.tooltip-container .ml4 {
    margin-left: 4px
}


@media screen and (width: 1440px) { 
   
    .tooltip-container {
        width: 180px;
    }

    .tooltip-container {
        height: 180px;
    }

    .tooltip-container .tooltip-svg {
        width: 100px;
        height: 100px;
    }

    .tooltip-container .mt4 {
        margin-top: 20px;
        font-size: 26px;
    }
}